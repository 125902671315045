/* src/styles/Footer.css */
.custom-footer {
  background-color: #34495e; /* Slightly different color from the main background */
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  margin: 0; /* Ensure no margin */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: max-height 0.3s ease;
  position: fixed;
  bottom: 0;
  width: 100%;
    z-index: 1000; /* I
}

.custom-footer.collapsed {
  max-height: 50px; /* Height when collapsed */
  overflow: hidden; /* Hide overflowing content */
}

.custom-footer.expanded {
  max-height: 200px; /* Adjust based on your content's height */
}

.custom-links-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  width: 100%; /* Ensure full width */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.custom-links-container p {
  margin: 0;
}

.custom-btn-link {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.custom-btn-link:hover {
  text-decoration: underline;
}

.custom-social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.footer-toggle {
  background: none;
  border: none;
  color: #ecf0f1;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  display: none; /* Hidden by default */
}

@media (max-width: 768px) {
  .footer-toggle {
    display: block; /* Show on mobile */
  }

  .custom-links-container {
    display: none; /* Hidden by default on mobile */
  }

  .custom-footer.expanded .custom-links-container {
    display: flex; /* Show when expanded */
    flex-direction: row; /* Arrange icons side by side */
    justify-content: center; /* Center icons */
    gap: 10px; /* Add some space between icons */
  }

  /* Hide text and show only icons on mobile */
  .custom-btn-link {
    display: flex;
    align-items: center;
    justify-content: center; /* Center icons */
    flex-direction: column; /* Stack icon above text */
  }

  .custom-btn-link span {
    display: none; /* Hide text on mobile */
  }
}
