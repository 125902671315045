/* src/styles/globalstyle.css */
html, body {
  font-family: 'Arial', sans-serif;
  background-color: #1c2833; /* Darker page background */
  margin: 0;
  padding: 0;
  color: #ecf0f1; /* Light text color */
  height: 100%;
  overflow: auto; /* Allow scrolling */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #2c3e50;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50; /* Ensure navbar has a background color */
}

.nav-links {
  display: flex;
  gap: 5px;
}

.nav-link {
  color: #ecf0f1;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.content {
  flex: 1;
  padding-top: 80px; /* Ensure content doesn't overlap with fixed navbar */
  padding-bottom: 80px; /* Add padding at the bottom to ensure space between content and footer */
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

h1, h2 {
  color: #ecf0f1; /* Lighter header text color */
  margin-bottom: 20px;
}

.description {
  color: #bdc3c7;
  margin-bottom: 40px;
  font-size: 18px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.info-container {
  margin-top: 20px;
  color: #bdc3c7;
  font-size: 16px; /* Larger text size for desktop */
}

.info-item {
  margin-bottom: 20px;
}

.address-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-link {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.address-link:hover {
  color: #2980b9;
}

.links-container, .tools-container, .games-container {
  margin: 20px 0;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px; /* Fixed width for buttons */
  max-width: 100%; /* Ensure buttons don't exceed screen width */
  color: #ecf0f1; /* Lighter text color */
  text-decoration: none; /* Remove underline */
  background-color: #3498db; /* Brighter button color */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 10px auto;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.btn-link:hover {
  background-color: #2980b9; /* Darker hover color */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); /* Inner shadow on hover */
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.copied-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
  animation: fade-in-out 2s;
}

@keyframes fade-in-out {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.info-toggle {
  cursor: pointer;
  color: #ecf0f1;
  font-size: 24px;
}

.info-toggle:hover {
  color: #bdc3c7;
}

.arrow-down, .arrow-up {
  margin-left: 10px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .info-container {
    font-size: 10px; /* Smaller text size for mobile */
  }

  .btn-link {
    width: 100%; /* Full width for mobile */
  }
}

